import { t } from '@/plugins/i18n'
import merchantAPI from '@api/merchant/merchant'
import { computed, ref, watch } from '@vue/composition-api'

export default function useMerchantsList() {
  const merchantList = ref([])
  const merchantTotal = ref(0)

  const tableColumns = [
    { text: 'ID', value: 'id' },

    // { text: 'UUID', value: 'uuid', sortable: false },
    { text: 'Name', value: 'name', sortable: false },
    { text: 'Code', value: 'code' },
    { text: 'Channel', value: 'channel_id' },
    { text: 'Country', value: 'country_id' },
    { text: 'Active?', value: 'active', sortable: false },
    { text: 'Joined At', value: 'joined_at' },
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const searchQuery = ref('')
  const loading = ref(false)
  const tableOptions = ref({
    itemsPerPage: 15,
    sortBy: ['id'],
    sortDesc: [true],
  })
  const selectedRows = ref([])

  const fetchMerchants = async () => {
    const merchants = await merchantAPI.getAll({
      tableOptions: tableOptions.value,
      searchQuery: searchQuery.value,
    })

    const { records, pagination } = merchants

    merchantList.value = records
    merchantTotal.value = pagination.total

    // remove loading state
    loading.value = false
  }

  const search = () => {
    loading.value = true
    selectedRows.value = []

    fetchMerchants()
  }

  const merchantOptions = computed(() => merchantList.value.map(merchant => ({ label: `${t(merchant.name)} (${merchant.code})`, value: merchant.id })))

  //
  watch([tableOptions], () => {
    search()
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    merchantList,
    merchantTotal,
    tableColumns,
    searchQuery,
    loading,
    tableOptions,
    selectedRows,
    fetchMerchants,
    merchantOptions,
    search,
  }
}
